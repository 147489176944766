import { render, staticRenderFns } from "./MaiorDificuldadeTrabalhoDass.vue?vue&type=template&id=64246554&scoped=true&"
import script from "./MaiorDificuldadeTrabalhoDass.vue?vue&type=script&lang=js&"
export * from "./MaiorDificuldadeTrabalhoDass.vue?vue&type=script&lang=js&"
import style0 from "./MaiorDificuldadeTrabalhoDass.vue?vue&type=style&index=0&id=64246554&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64246554",
  null
  
)

export default component.exports