<template>
    <div>
      <div
        v-if="ApplicationData.dadosGraficoEscalaRespostaTotal.length > 0"
        class="px-2"
      >
        <b-overlay
          v-if="loadingShow"
          :show="loadingShow"
          rounded="lg"
          opacity="0.6"
          class="mt-2"
        />
        <b-row
          class="no-gutters"
        >
          <b-col
            sm="12"
            md="12"
            lg="12"
            xl="6"
            cols="12"
            class="pr-sm-1 pr-0 mb-1"
          >
            <vue-perfect-scrollbar
              class="height-custom"
              :settings="settings"
            >
              <div>
                <div
                  v-if="ApplicationData.nomeQuestionario == 'PHQ-9'"
                  class="d-flex align-items-center justify-content-center height-custom"
                >
                  <div class="minimal-graph-size-pizza d-flex align-items-center justify-content-center align-self-center">
                    <escala-respostas-grafico
                      :key="childKey"
                      :ApplicationData="ApplicationData"
                    />
                  </div>
                </div>
                <div
                  v-else
                  class="d-flex align-items-center justify-content-center height-custom"
                >
                  <div class="minimal-graph-size-pizza d-flex align-items-center justify-content-center align-self-center">
                    <escala-respostas-grafico
                      v-if="carregarCard"
                      :key="childKey"
                      :ApplicationData="ApplicationData"
                      :dados="dadosSelecionados"
                    />
                  </div>
                </div>

              </div>
            </vue-perfect-scrollbar>
          </b-col>
          <b-col
            sm="12"
            md="12"
            lg="12"
            xl="6"
            class="d-flex text-center center-div align-self-center espacamento-top"
          >
            <div>
              <div class="mb-1"  v-if="ApplicationData.nomeQuestionario == 'DASS-21'">
                <h6>Escolha um filtro:</h6>
              </div>
              <div
                class="d-flex justify-content-center align-content-center"
                v-if="ApplicationData.nomeQuestionario == 'DASS-21'"
              >
                <v-select
                  v-model="opcaoSelecionada"
                  @input="atualizaTipoSelecionado"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="opcoesSelect"
                  :clearable="false"
                  class="select-custom-filtros"
                  label="descricao"
                >
                  <span slot="no-options">Nenhum filtro encontrado.</span>
                </v-select>
              </div>
              <div :class="{'d-flex': true, 'justify-content-center': true, 'align-items-center': true, 'mb-sm-0 mb-2': true, 'mt-2': ApplicationData.nomeQuestionario == 'DASS-21'}">
                <div class="grouping-escalas">
                  <div class="align-by-line">
                    <div class="spacement-bullet">
                      <div class="bullet-escala bullet-color-sem-sintomas"></div>
                    </div>
                    <div class="spacement-label">
                      <div class="item-escala">Escala 0</div>
                        <span class="separador">|</span>
                        <span class="text-muted fonte"> Sem sintomas</span>
                      </div>
                    </div>
                    <div class="align-by-line">
                      <div  class="spacement-bullet">
                        <div class="bullet-escala bullet-color-sintomas-minimos"></div>
                      </div>
                      <div class="spacement-label">
                        <div class="item-escala">Escala 1</div>
                        <span class="separador">|</span>
                        <span class="text-muted fonte"> Sintomas leves</span>
                      </div>
                    </div>
                  <div class="align-by-line">
                    <div class="spacement-bullet">
                      <div class="bullet-escala bullet-color-sintomas-leves"></div>
                    </div>
                    <div class="spacement-label">
                      <div class="item-escala">Escala 2</div>
                      <span class="separador">|</span>
                      <span class="text-muted fonte">Sintomas moderados</span>
                    </div>
                  </div>
                  <div class="align-by-line">
                    <div class="spacement-bullet">
                      <div class="bullet-escala bullet-color-sintomas-moderados"></div>
                    </div>
                    <div class="spacement-label">
                      <div class="item-escala">Escala 3</div>
                      <span class="separador">|</span>
                      <span class="text-muted fonte">Sintomas graves</span>
                    </div>
                  </div>
                  <div class="align-by-line">
                    <div class="spacement-bullet">
                      <div class="bullet-escala bullet-color-sintomas-graves"></div>
                    </div>
                    <div class="spacement-label">
                      <div class="item-escala">Escala 4</div>
                      <span class="separador">|</span>
                      <span class="text-muted fonte">Sintomas muito graves</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
        <div
          v-else
          class="height-custom d-flex align-items-center justify-content-center"
        >
          <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
      </div>
    </div>
  </template>
  
  
  <script>
  import {
    BRow,
    BCol,
    BOverlay
  } from "bootstrap-vue";
  import EscalaRespostasGrafico from "@/views/components/custom/dashboard-aplicacao-cora-eventos/EscalaRespostasGrafico.vue";
  import vSelect from "vue-select";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import CardSemDados from "./CardSemDados.vue";

  export default {
    components: {
      VuePerfectScrollbar,
      vSelect,
      EscalaRespostasGrafico,
      BRow,
      BCol,
      BOverlay,
      CardSemDados
    },
    props: {
      dados: {
        type: Array,
        required: false
      },
      ApplicationData: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        loadingShow: true,
        childKey: 0,
        opcaoSelecionada: null,
        carregarCard:false,
        opcoesSelect: [
          "Depressão",
          "Ansiedade",
          "Estresse"
        ],
        dadosSelecionados: [],
        settings: {
          maxScrollbarLength: 70,
          wheelSpeed: 0.2,
          wheelPropagation: false,
        },
        validarCardSemDados: {
          verificadorVazio: true,
          tipagemErro: 0
        }
      };
    },
    mounted() {
      this.opcaoSelecionada = this.opcoesSelect[0]
      this.atualizaTipoSelecionado();
      this.loadingShow = false
    },
    methods: {
      atualizaTipoSelecionado(tipoString = 'Depressão') {
        this.dadosSelecionados = []
        this.$props.ApplicationData.dadosGraficoEscalaRespostaTotal.forEach(element => {
              if(element.doenca == tipoString){
                 this.dadosSelecionados.push(element)
              }
        });

        this.carregarCard = true
        this.childKey++
      }
    }
  };
  </script>

  <style scoped>
  .height-custom{
    height:280px;
  }
  .bullet-color-sintomas-minimos{
    background-color: #2772C0;
  }
  .bullet-color-sintomas-leves{
    background-color: #002060;
  }
  .bullet-color-sintomas-moderados{
    background-color: #7030A0;
  }
  .bullet-color-sintomas-graves{
    background-color: #AA228C;
  }
  .bullet-color-sem-sintomas{
    background-color: #36BA00;
  }
  .grouping-escalas{
    font-size: 14px;
    font-weight: 500;
  }
  .align-by-line{
    display: flex;
    align-items: center;
    height: 15px;
    margin-bottom: 12px;
  }
  .separador{
    margin-right: 7px;
  }
  .spacement-bullet{
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  .bullet-escala{
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .spacement-label{
    height: 100%;
    display: flex;
    align-items: center;
  }
  .item-escala{
    text-align: initial;
    min-width: 63px;
  }
  .select-custom-filtros{
    min-width: 97px;
  }
  .select-custom-filtros::v-deep .vs__dropdown-toggle{
    border: 1px solid #7030A0;
    justify-content: center;
    align-items: center;
  }
  .select-custom-filtros::v-deep .vs__selected-options{
    padding: 0;
    flex-basis: inherit;
    flex-grow: initial;
    padding-left: 5px;
    padding-top: 2px;
  }
  .select-custom-filtros::v-deep .vs__search{
    margin: 0;
    padding: 0;
    max-height: 0;
    max-width: 0;
  }
  .select-custom-filtros::v-deep .vs__selected{
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #7030A0 !important;
    padding-right: 3px;
  }
  .select-custom-filtros.vs--single.vs--open::v-deep .vs__selected{
    position: relative;
  }
  .select-custom-filtros::v-deep .feather-chevron-down{
    color: #7030A0;
  }
  .minimal-graph-size-pizza{
    height: 100%;
    width: 100%;
    max-height: 240px;
    max-width: 240px;
  }

  @media(min-width: 3000px){
    .grouping-escalas{
      font-size: 34px !important;
    }
    .item-escala{
      min-width: 178px;
    }
    .align-by-line{
      height: 35px;
    }

    .bullet-escala{
      width: 30px;
      height: 30px;
    }
  }

  @media(min-width: 2000px) and (max-width: 3000px){
    .grouping-escalas{
      font-size: 20px !important;
    }
    .separador{
      margin-right: 4px;
    }
    .item-escala{
      min-width: 85px;
    }
    .align-by-line{
      height: 30px;
    }
  }
  @media (min-width: 1600px) {
    .select-custom-filtros::v-deep .vs__selected{
      font-size: 13px;
    }
    .grouping-escalas{
      font-size: 14px;
    }
  }

  @media (min-width: 1281px) and (max-width: 1471px) {
    .grouping-escalas{
      font-size: 12px;
    }
    .item-escala{
      min-width: 52px;
    }
    .separador{
      margin-right: 5px;
    }
  }

  @media (max-width: 1380px) {
    .item-escala{
      min-width: 52px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1280px){
    .grouping-escalas{
      font-size: 10px;
    }
    .item-escala{
      min-width: 45px;
    }

  }
  @media(min-width: 992px) and (max-width: 1199px){

    .grouping-escalas{
      font-size:16px;
    }
    .height-custom{
      height: 370px;
    }
    .item-escala{
      min-width: 75px;
    }
    .center-div{
      justify-content: center;
    }
  }

  @media(min-width: 768px) and (max-width: 991px){

    .grouping-escalas{
      font-size:16px;
    }
    .height-custom{
      height: 370px;
    }
    .item-escala{
      min-width: 75px;
    }
    .center-div{
      justify-content: center;
    }
  }

  @media (max-width: 767px) {
      .center-div{
        justify-content: center;
      }
      .grouping-escalas{
        font-size:13px;
      }
    }
  </style>
  
  