<template>
    <b-overlay :show="!loadingShow" rounded="lg" opacity="0.6">
        <section>
            <b-navbar class="navbar">
                <b-container class="d-flex align-items-center justify-content-center">
                    <!-- Logo ou imagem centralizada -->
                    <img :src="clienteSesi" class="logo-cliente-sesi" alt="Logo" height="80">
                </b-container>
            </b-navbar>

            <b-container class="mt-5 container-principal col-11">
                <b-row class="d-flex justify-content-center">
                    <b-col lg="12" md="12">
                        <div class="d-flex justify-content-between flex-lg-row flex-md-row flex-column mt-2 espacamento">
                            <div>
                                <span class="titulo">DASHBOARD <span class="nome-empresa">- {{ dados.nomeEmpresa }}</span></span>
                            </div>
                            <div class="d-flex align-items-center">
                                ATUALIZADO EM {{ horarioAtual }}
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col lg="12" md="12" v-if="loadingShow">
                        <b-card no-body>
                            <div class="altura_linha_1_dass">
                                <titulo-generico titulo="Escala de Respostas" subtitulo="Atualizado de acordo com último ciclo"/>
                                <escala-respostas-aplicacao v-if="loadingShow"
                                    :ApplicationData="dados"
                                />
                            </div>
                        </b-card>
                    </b-col>
                    <b-col lg="12" md="12" v-if="loadingShow">
                        <b-card no-body>
                            <div class="altura_linha_genero_dass">
                                <titulo-generico titulo="Respostas por gênero"/>
                                <escala-respostas-por-genero-aplicacao v-if="loadingShow"
                                    :ApplicationData="dados"
                                    :telaCheia="false"
                                />
                            </div>
                        </b-card>
                    </b-col>
                </b-row>


                <b-row class="mt-2">
                    <b-col lg="6" md="6">
                        <b-card no-body>
                        <div class="altura_linha_3_dass">
                            <titulo-generico titulo="Áreas de maior dificuldade"/>
                            <area-maior-dificuldade-dass v-if="loadingShow"
                                :dados="dados.dadosAreaMaiorDificuldade"
                            />
                        </div>
                        </b-card>
                    </b-col>
                    <b-col lg="6" md="6">
                        <b-card no-body>
                            <div class="altura_linha_3_dass">
                            <titulo-generico titulo="Maiores dificuldade no trabalho"/>
                            <maior-dificuldade-trabalho-dass v-if="loadingShow"
                                :dados="dados.dadosMaiorDificuldadeTrabalho"
                            />
                            </div>
                        </b-card>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col lg="8" md="12" v-if="loadingShow">
                        <resposta-por-faixa-etaria-aplicacao
                            :ApplicationData="dados"
                        />
                    </b-col>

                    <b-col lg="4" md="12" v-if="loadingShow">
                        <escala-respostas-finalizadas
                            :ApplicationData="dados"
                        />
                    </b-col>
                </b-row>
            </b-container>

        </section>
    </b-overlay>
</template>


<script>

import { BNavbar, BContainer,BCol,BRow,BOverlay, BCard} from 'bootstrap-vue'
import clienteSesi from '@/assets/images/logo/cliente-sesi-logo.svg'
import AreaMaiorDificuldadeDass from "@/views/components/custom/dashboard-aplicacao-cora-eventos/AreaMaiorDificuldadeDass.vue";
import MaiorDificuldadeTrabalhoDass from "@/views/components/custom/dashboard-aplicacao-cora-eventos/MaiorDificuldadeTrabalhoDass.vue";
import EscalaRespostasAplicacao from "@/views/components/custom/dashboard-aplicacao-cora-eventos/EscalaRespostasAplicacao.vue";
import RespostaPorFaixaEtariaAplicacao from "@/views/components/custom/dashboard-aplicacao-cora-eventos/RespostaPorFaixaEtariaAplicacao.vue";
import EscalaRespostasFinalizadas from "@/views/components/custom/dashboard-aplicacao-cora-eventos/EscalaRespostasFinalizadas.vue";
import EscalaRespostasPorGeneroAplicacao from "@/views/components/custom/dashboard-aplicacao-cora-eventos/EscalaRespostasPorGeneroAplicacao.vue";
import { modalGenericFillableErrorWithReload } from '@/libs/sweetalerts'
import TituloGenerico from "@/views/pages/aplicacoes/visualizar-aplicacao/TituloGenerico.vue";

export default {

    components: {
        BNavbar,
        BContainer,
        BCard,
        BCol,
        BRow,
        BOverlay,
        EscalaRespostasAplicacao,
        AreaMaiorDificuldadeDass,
        MaiorDificuldadeTrabalhoDass,
        RespostaPorFaixaEtariaAplicacao,
        TituloGenerico,
        EscalaRespostasFinalizadas,
        EscalaRespostasPorGeneroAplicacao,
    },

    data() {
        return {
            clienteSesi: clienteSesi,
            loadingShow: false,
            dados: {},
            horarioAtual: null,
            dadosAreas: 
      [
        {
          "id_empresa": 51,
          "id_fase": 1,
          "conteudo": "Trabalho",
          "totalizador": 62,
          "filtro": 62
        },
        {
          "id_empresa": 51,
          "id_fase": 1,
          "conteudo": "Comigo",
          "totalizador": 30,
          "filtro": 62
        },
        {
          "id_empresa": 51,
          "id_fase": 1,
          "conteudo": "Relacionamento",
          "totalizador": 28,
          "filtro": 62
        },
        {
          "id_empresa": 51,
          "id_fase": 1,
          "conteudo": "Saúde",
          "totalizador": 24,
          "filtro": 62
        },
        {
          "id_empresa": 51,
          "id_fase": 1,
          "conteudo": "Família",
          "totalizador": 24,
          "filtro": 62
        },
        {
          "id_empresa": 51,
          "id_fase": 1,
          "conteudo": "Sono",
          "totalizador": 22,
          "filtro": 62
        },
        {
          "id_empresa": 51,
          "id_fase": 1,
          "conteudo": "Problemas Financeiros",
          "totalizador": 21,
          "filtro": 62
        },
        {
          "id_empresa": 51,
          "id_fase": 1,
          "conteudo": "Exercícios",
          "totalizador": 12,
          "filtro": 62
        },
        {
          "id_empresa": 51,
          "id_fase": 1,
          "conteudo": "Lazer",
          "totalizador": 7,
          "filtro": 62
        },
        {
          "id_empresa": 51,
          "id_fase": 1,
          "conteudo": "Amizades",
          "totalizador": 5,
          "filtro": 62
        },
        {
          "id_empresa": 51,
          "id_fase": 1,
          "conteudo": "Outra coisa",
          "totalizador": 4,
          "filtro": 62
        },
        {
          "id_empresa": 51,
          "id_fase": 1,
          "conteudo": "Não sei ou prefiro não dizer",
          "totalizador": 1,
          "filtro": 62
        }
      ],
      dadosMaiorDificuldade: 
      [
        {
          "id_empresa": 36,
          "id_fase": 1,
          "conteudo": "Disposição para o trabalho",
          "totalizador": 38,
          "filtro": "152"
        },
        {
          "id_empresa": 36,
          "id_fase": 1,
          "conteudo": "Sobrecarga",
          "totalizador": 25,
          "filtro": "152"
        },
        {
          "id_empresa": 36,
          "id_fase": 1,
          "conteudo": "Produtividade",
          "totalizador": 24,
          "filtro": "152"
        },
        {
          "id_empresa": 36,
          "id_fase": 1,
          "conteudo": "Sentimento de Valorização",
          "totalizador": 14,
          "filtro": "152"
        },
        {
          "id_empresa": 36,
          "id_fase": 1,
          "conteudo": "Relacionamento com liderança",
          "totalizador": 12,
          "filtro": "152"
        },
        {
          "id_empresa": 36,
          "id_fase": 1,
          "conteudo": "Clareza das funções e responsabilidades",
          "totalizador": 11,
          "filtro": "152"
        },
        {
          "id_empresa": 36,
          "id_fase": 1,
          "conteudo": "Crescimento Profissional",
          "totalizador": 10,
          "filtro": "152"
        },
        {
          "id_empresa": 36,
          "id_fase": 1,
          "conteudo": "Relacionamento com colegas",
          "totalizador": 6,
          "filtro": "152"
        },
        {
          "id_empresa": 36,
          "id_fase": 1,
          "conteudo": "Comunicação",
          "totalizador": 5,
          "filtro": "152"
        },
        {
          "id_empresa": 36,
          "id_fase": 1,
          "conteudo": "Recursos de Trabalho",
          "totalizador": 3,
          "filtro": "152"
        },
        {
          "id_empresa": 36,
          "id_fase": 1,
          "conteudo": "Segurança no ambiente de trabalho",
          "totalizador": 3,
          "filtro": "152"
        },
        {
          "id_empresa": 36,
          "id_fase": 1,
          "conteudo": "Assédio",
          "totalizador": 1,
          "filtro": "152"
        }
      ],

        }
    },

    created() {
        this.buscaAplicacao();
    },

    methods: {

        buscaAplicacao(){
            let params = {
                arquivoAnexo: this.$route.params.arquivoAnexo
            }
            this.$http.get(this.$api.buscaAplicacaoPorArquivoAnexo, {params})
            .then((response) => {
                this.escalaRespostasAplicacao = response.data
                this.dados = response.data

                let dataAtual = new Date();

                // Obtém a hora atual no fuso horário de São Paulo
                let horaSaoPaulo = new Intl.DateTimeFormat('pt-br', { timeZone: 'America/Sao_Paulo', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(dataAtual);

                // Separa a hora e os minutos
                let [hora, minuto] = horaSaoPaulo.split(':');

                // Resultado final
                this.horarioAtual = hora + ":" + minuto;


                this.loadingShow = true
            }).catch(() => {
              modalGenericFillableErrorWithReload(
                "Ocorreu um erro no dashboard DASS-21. Tente novamente mais tarde"
              )
            })
        }

    },
}
</script>

<style scoped>
.altura_linha_1_dass{
  height: 357px;
}
.altura_linha_genero_dass{
  height: 385px;
}
.navbar {
    background-color: #002060;
    height: 112px;
}
.logo-cliente-sesi{
    width: 412.02px;
    height: 43px;

}
.container-principal{
    background-color:white ;
    border-radius: 10px;
    padding: 50px;
}

.altura_linha_3_dass{
  height: 357px;
}

.titulo{
    color:#4B4B4B;
    font-family: 'Monsterrat', sans-serif;
    font-weight: 500;
    font-size: 28px;
    line-height: 34.13px;
}

.nome-empresa{
    color:#4B4B4B;
    font-family: 'Monsterrat', sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 34.13px;
}
@media (min-width: 3000px){
    .altura_linha_1_dass{
        height: 445px;
    }
}
@media(max-width: 1200px){
    .altura_linha_genero_dass{
        height: auto;
    }
}
@media (max-width: 1199px) {
    .container-principal{
        padding: 0px;
    }
}

@media (min-width: 1015px) and (max-width: 1057px) {
    .container-principal{
        padding: 0px;
    }
    .altura_grafico{
        height: 710px !important;
    }
}

@media (min-width: 577px) and (max-width: 1199px) {
    .espacamento{
        padding: 14px;
    }
}

@media (max-width: 576px) {
    .navbar {
        height: 80px;
    }
    .logo-cliente-sesi{
        width: 250px;
        height: 31px;
    }
    .container-principal{
        padding: 18px;
    }

}

@media (max-width: 767px) {
  .altura_linha_1_dass{
    height: 610px;
  }
}

@media (max-width: 400px) {
    .container-principal{
        padding: 15px;
    }

}

@media (min-width: 992px) and (max-width: 1199px) {
  .altura_linha_1_dass{
    height: 680px;
  }
}

@media ( min-width:768px) and (max-width: 991px) {
    .container-principal{
      padding: 0px;
    }
    .altura_linha_1_dass{
        height: 700px;
    }
  }
</style>
