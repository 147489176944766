<template>
    <div>
      <div
        v-if="dados && dados.length > 0" 
      >
        <b-overlay
          v-if="loadingShow"
          :show="loadingShow"
          rounded="lg"
          opacity="0.6"
          class="mt-2"
        />
        <vue-perfect-scrollbar
          class="mx-2 pr-2 scroll-area"
          :settings="settings"
        >
          <div>
            <b-row
              v-for="item in dadosParaOComponenteFuncionar"
              :key="item.id"
              class="no-gutters mb-1 align-items-center"
            >
              <b-col
                xl="4"
                lg="4"
                md="12"
                class="text-left text-lg-right pr-1"
              >
                <div>{{ item.descricao }}</div>
              </b-col>
              <b-col
                xl="8"
                lg="8"
                md="12"
              >
                <b-progress
                  max="100"
                  class="progress-bar-info"
                >
                  <b-progress-bar
                    v-if="item.porcentagem >= porcentagemResponsivo"
                    :value="item.porcentagem"
                    :label="item.porcentagem + '%'"
                    class="cor-barra-gradiente"
                  />
                  <b-progress-bar
                    v-else
                    :value="item.porcentagem"
                    :label="''"
                    class="cor-barra-gradiente"
                  />
                </b-progress>
              </b-col>
            </b-row>
          </div>
        </vue-perfect-scrollbar>
      </div>
      <div
        v-else
        class="scroll-area d-flex align-items-center justify-content-center"
      >
        <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
      </div>
    </div>
  </template>
  
  <script>
  import { BProgress, BCardTitle, BCardHeader, BProgressBar, BCardText, BCard, BRow, BCol, BOverlay } from 'bootstrap-vue'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import CardSemDados from "./CardSemDados.vue";
  
  export default {
    components: {
      BCard,
      BProgress,
      BProgressBar,
      BCardText,
      BCardHeader,
      BRow,
      BCol,
      VuePerfectScrollbar,
      BOverlay,
      BCardTitle,
      CardSemDados
    },
    props: {
      dados: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        validarCardSemDados: {
          verificadorVazio: true,
          tipagemErro: 0
        },
        loadingShow: true,
        dadosParaOComponenteFuncionar: [],
        max: 100,
        porcentagemResponsivo: this.visibilidadeComprimento(),
        settings: {
          maxScrollbarLength: 70,
          wheelSpeed: 0.3,
          wheelPropagation: false
        }
      }
    },
    async mounted(){
      await this.preencheGrafico()
      window.addEventListener('resize', () => {
        this.porcentagemResponsivo = this.visibilidadeComprimento();
      });
    },
    methods: {
      preencheGrafico(){
        this.preparaPorcentagem()
        this.loadingShow = false;
      },
      preparaPorcentagem(){
        this.dadosParaOComponenteFuncionar = this.ordenaQuantidade();
        for(const element of this.dadosParaOComponenteFuncionar){
            element['porcentagem'] = Math.floor((element['quantidade']/element['total'])*100)
        }
      },

      ordenaQuantidade(){
        if(this.$props.dados.length > 0){
            return this.$props.dados.sort((a, b) => b.quantidade - a.quantidade);
        }

        return this.$props.dados
      },
      visibilidadeComprimento(){
        if(window.innerWidth >= 400 && window.innerWidth <= 470){
          return 10;
        }
        if(window.innerWidth < 400){
          return 15;
        }
        return 5;
      }
    }
  }
  </script>
  <style scoped>

  .cor-barra-gradiente{
    background-image: linear-gradient(to right, #3A60B7, #2C2679);
  }
  .scroll-area{
    height: 270px;
  }
  .subTitulo{
      text-align: center;
      font-size: 13px;
      margin-bottom: 10px;
  }
  .nome-barra{
    margin-top:-5px;
    text-align: right;
    font-size: 15px;
  }
  .barra-gradiente{
    background-image: linear-gradient(to right, #3A60B7, #2C2679);
  }
  .titulo_margem{
    margin-left: -20px;
  }
  .card-height{
    height: 340px;
  }
  
  .titulo_subtitulo_responsivo{
    margin-left: -20px;
    margin-top: -25px;
    margin-bottom: -15px;
  }
  .margem-titulo_subtitulo_responsivo{
    margin-top: 5px;
  }
  
  .margem-subtitulo_responsivo{
    margin: 5px -1.5rem 0 auto;
  }
  
  .tamanho-minimo-row{
    min-width:340px;
  }
  
  @media (max-width: 1600px) {
    .subTitulo{
      font-size: 8px;
      margin-bottom: 5px;
    }
  }
  
  @media (min-width: 1441px) and (max-width: 1465px)
  {
    .nome-barra{
      font-size: 11px !important;
    }
  }
  @media (min-width: 1441px) and (max-width: 1750px) {
    .col-xl-2{
      flex:0 0 25%;
      max-width:25%
    }
    .nome-barra{
      margin-top:-5px;
      text-align: right;
      font-size: 12px;
    }
    .col-xl-10{
      flex:0 0 75%;
      max-width:75%
    }
  }
  
    @media (min-width: 1200px) and (max-width: 1440px) {
        .col-xl-2{
            flex:0 0 33.3333333333%;
            max-width:33.3333333333%
        }
        .col-xl-10{
            flex:0 0 66.6666666667%;
            max-width:66.6666666667%
        }
        .nome-barra{
            font-size: 9px;
        }

        .subTitulo{
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
  
    @media (max-width: 1199px) {
      .nome-barra{
        text-align: left;
        font-size: 0.7rem;
      }
    }
  
    @media (min-width: 992px) and (max-width: 1199px){
      .tamanho-minimo-row{
        min-width:440px;
      }
    }
  
    @media (max-width: 767px){
      .card-height{
        height: 100%;
        margin-bottom: -1rem;
      }
    }
  
    @media (max-width: 458px){
      .subTitulo{
        margin-bottom: 0;
      }
    }
  </style>
  