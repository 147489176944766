<template>
    <vue-apex-charts
        v-if="mostraGrafico"
        :height="alturaGrafico"
        ref="myChart"
        width="100%"
        type="bar"
        :options="chartOptions"
        :series="chartOptions.series" 
    />
  </template>

  <script>
  import { BCard, BOverlay } from "bootstrap-vue";
  import VueApexCharts from "vue-apexcharts";
  import CardSemDados from "@/views/pages/dashboard/CardSemDados.vue";

  export default {
    components: {
      VueApexCharts,
      BCard,
      CardSemDados,
      BOverlay,
    },
    props: {
      ApplicationData: {
        type: Object,
        default() {
          return {};
        },
        required: true,
      },
      rotulo: {
        type: String,
        required: true,
        default: "",
      },
      nomeCard: {
        type: String,
        required: true,
        default: "",
      },
      telaCheia: {
        type: Boolean,
        default: true,

        required: false,
      },
    },
    mounted() {
      this.buscaDadosCard();
      window.addEventListener('resize', () => {
        this.alturaGrafico = this.verificaAltura();
        this.larguraGrafico = this.verificaLargura();
      });

    },
    created() {
      this.reDim();
      window.addEventListener("resize", this.reSize);
    },
    destroyed() {
      window.removeEventListener("resize", this.reSize);
    },

    methods: {
      reDim() {
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            yaxis: {
              labels: {
                show: true,
                formatter: function (val) {
                  return Math.floor(val);
                },
              },
            },
          },
        };
      },
      reSize() {
        this.reDim();
      },
      verificaLargura(){
        if(window.innerWidth >= 2100 && window.innerWidth <= 3000){
          return '150%';
        }
        if(window.innerWidth >= 2000 && window.innerWidth < 2100){
          return '130%';
        }
        if(window.innerWidth > 3000 ){
          return '220%';
        }
        return '100%';
      },
      verificaAltura(){
        if(window.innerWidth > 3000){
          return '300';
        }
        if(window.innerWidth > 2000){
          return '250';
        }
        if(window.innerWidth < 768){
          return '200';
        }

        return '250';
      },

      verificaLimiteMaximoGrafico(value) {
        this.limiteMaximoGrafico =
          value > this.limiteMaximoGrafico ? value : this.limiteMaximoGrafico;
        this.reDim();
      },
      buscaDadosCard() {
        this.mostraGrafico = false;
        this.chartOptions.colors = [];
        this.chartOptions.xaxis.categories = [];
        this.chartOptions.series[0].data = [];

          this.dadosParaOComponenteFuncionar = this.ordenarRetorno(this.ApplicationData.dadosGraficosPorGenero);
          for (const element of this.dadosParaOComponenteFuncionar) {
              if(this.rotulo == element.genero){
                if(this.ApplicationData.nomeQuestionario == 'PHQ-9'){
                  this.chartOptions.series[0].data.push(element.total_genero);
                  this.verificaLimiteMaximoGrafico(element.escala);
                  this.chartOptions.colors.push(
                    this.aliasCores(element.escala)
                  );
                  this.chartOptions.xaxis.categories.push(element.escala);
              }else if(this.ApplicationData.nomeQuestionario == 'DASS-21'){
                  this.chartOptions.series[0].data.push(element.total_genero);
                  this.verificaLimiteMaximoGrafico(element.doenca);
                  this.chartOptions.colors.push(
                    this.aliasCores(element.doenca)
                  );
                  this.chartOptions.xaxis.categories.push(element.doenca);
              }
            }

          }

          this.mostraGrafico = true;   

      },

      compare(a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      },


      ordenarRetorno(dados) {
        if(this.ApplicationData.nomeQuestionario == 'PHQ-9' ){
          for (let element of dados) {
            switch (element["id_escala"]) {
              case 0:
                element["order"] = "1";
                break;
              case 1:
                element["order"] = "2";
                break;
              case 2:
                element["order"] = "3";
                break;
              case 3:
                element["order"] = "4";
                break;
              case 4:
                element["order"] = "5";
                break;
            }
          }
          return dados.sort(this.compare);
        }else if(this.ApplicationData.nomeQuestionario == 'DASS-21'){
          for (let element of dados) {
            switch (element["id_doenca"]) {
              case 0:
                element["order"] = "1";
                break;
              case 1:
                element["order"] = "2";
                break;
              case 2:
                element["order"] = "3";
                break;
              case 3:
                element["order"] = "4";
                break;
            }
          }
          return dados.sort(this.compare);
        }
      },


      aliasCores(escala) {
        if(this.ApplicationData.nomeQuestionario == 'PHQ-9'){
          switch (escala) {
            case 'Sem Sintomas':
              escala = "#36BA00";
              break;
            case 'Sintomas Leves':
              escala = "#2772C0";
              break;
            case 'Sintomas Moderados':
              escala = "#002060";
              break;
            case 'Sintomas Graves':
              escala = "#7030A0";
              break;
            case 'Sintomas Muito Graves':
              escala = "#AA228C";
              break;
          }

          return escala;
        }else if(this.ApplicationData.nomeQuestionario == 'DASS-21'){
          switch (escala) {
            case 'Sem Sintomas':
              escala = "#36BA00";
              break;
            case 'Depressão':
              escala = "#2772C0";
              break;
            case 'Ansiedade':
              escala = "#002060";
              break;
            case 'Estresse':
              escala = "#7030A0";
              break;
          }

          return escala;
        }
      },
    },
    data() {
      return {
        mostraGrafico: false,
        width: this.larguraGrafico,
        limiteMaximoGrafico: 2,
        dadosParaOComponenteFuncionar: [],
        larguraGrafico: this.verificaLargura(),
        alturaGrafico: this.verificaAltura(),
        chartOptions: {
          series: [
            {
              name: "Respostas",
              data: [],
            },
          ],
          chart: {
            toolbar: {
              show: false,
            },
            lines: {
              show: true,
            },
            fontFamily: "'Montserrat', sans-serif",
            fontStyle: "normal",
            fontWeight: "400",
            type: "bar",
          },
          colors: ['#B9B9C3'],

          plotOptions: {
            bar: {
              columnWidth: "25%",
              distributed: true,
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          grid: {
            show: true,

            strokeDashArray: 0,
            position: "back",
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
            row: {
              colors: undefined,
              opacity: 0.5,
            },
            column: {
              colors: undefined,
              opacity: 0.5,
            },
            padding: {
              top: 0,
              right: 20,
              bottom: 0,
              left: 20,
            },
          },

          yaxis: {
            labels: {
              offsetX: -15,
              offsetY: 0,
              //show: false,
            },
          },
          xaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            categories: [],
            labels: {
              show: false,
              style: {
                fontSize: "12px",
              },
            },
            title: {
              text: this.nomeCard,

              style: {
                color: "#B9B9C3",
                fontWeight: "500",
                fontStyle: "normal",
              },
            },
          },
        },
      };
    },

    watch: {
        ApplicationData: function () {
        this.series = [];
        if(this.telaCheia){
           this.buscaDadosCard();
        }

      },
    },
  };
</script>
<style scoped>

  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

  .altura_grafico{
      max-width: 100%;
  }
  .card-body{
    padding: 0 !important;
  }

  section {
    height: 370px !important;
  }

  @media (max-width: 1199) {
    .altura_grafico{
      max-height: 340px;
      max-width: 270px;

    }
  }
  @media (max-width: 420px) {
    .altura_grafico{
      max-width: 270px;

    }
  }
  @media (max-width: 380px) {
    .altura_grafico{
      max-height: 270px;
      max-width: 270px;

    }
  }
  @media (max-width: 350px) {
    .altura_grafico{
      max-height: 240px;
      max-width: 270px;
    }
  }
</style>


