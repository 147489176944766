<template>
    <div>
      <div
        v-if="dados && dados.length > 0"
      >
        <b-overlay
          v-if="loadingShow"
          :show="loadingShow"
          rounded="lg"
          opacity="0.6"
          class="mt-2"
        />
        <vue-perfect-scrollbar
          class="scroll-area mx-2 pr-2"
          :settings="settings"
        >
          <div
            v-for="(dados, id) in dadosParaOComponenteFuncionar"
            :key="id"
            class="d-flex justify-content-between align-items-center mb-1"
          >
            <div class="pr-2">
              {{ dados.descricao }}
            </div>
  
            <div class="d-flex align-items-center">
              <small class="text-muted mr-50">{{porcentagemPorDoisSemNulo(dados.quantidade,dados.total)}}%</small>
              <vue-apex-charts
                type="radialBar"
                height="30"
                width="30"
                :options="chartData[id].options ? chartData[id].options : {}"
                :series="chartData[id].series ? chartData[id].series : {}"
              />
            </div>
          </div>
        </vue-perfect-scrollbar>
      </div>
      <div
        v-else
        class="scroll-area d-flex align-items-center justify-content-center"
      >
        <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
      </div>
    </div>
    </template>
  
    <script>
  
    import {
      BCard,
      BCardTitle,
      BCardHeader,
      BCardBody,
      BMedia,
      BMediaAside,
      BAvatar,
      BMediaBody,
      BOverlay,
      BButton,
      BRow,
    } from "bootstrap-vue";
    import VueApexCharts from "vue-apexcharts";
    import VuePerfectScrollbar from "vue-perfect-scrollbar";
    import CardSemDados from "./CardSemDados.vue";
    const $trackBgColor = "#e9ecef";
    const $fillColor = "#7030a0";
  
    export default {
      components: {
        BCard,
        BCardTitle,
        BCardHeader,
        BCardBody,
        BMedia,
        BMediaAside,
        BMediaBody,
        BAvatar,
        VueApexCharts,
        VuePerfectScrollbar,
        BOverlay,
        CardSemDados,
        BButton,
        BRow,
      },
      props: {
        dados: {
          type: Array,
          required: true
        }
      },
      data() {
        return {
          validarCardSemDados: {
            verificadorVazio: true,
            tipagemErro: 0,
          },
          loadingShow: false,
          tipoOrdemAlfa: true,
          dadosParaOComponenteFuncionar: [],
          settings: {
            maxScrollbarLength: 70,
            wheelSpeed: 0.3,
            wheelPropagation: false,
          },
          chartData: [],
          chart: {
            series: [0],
            options: {
              grid: {
                show: false,
                padding: {
                  left: -15,
                  right: -15,
                  top: -12,
                  bottom: -15,
                }
              },
              colors: [$fillColor],
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: "22%",
                  },
                  track: {
                    background: $trackBgColor,
                  },
                  dataLabels: {
                    showOn: "always",
                    name: {
                      show: false,
                    },
                    value: {
                      show: false,
                    }
                  }
                }
              },
              stroke: {
                lineCap: "round"
              }
            }
          }
        };
      },
  
      mounted() {
        this.dadosParaOComponenteFuncionar = this.ordenaQuantidade();
        this.criacaoPreenchimentoCharts();
      },
  
      methods: {
        adicionarCampoPorcentagem() {
          this.dadosParaOComponenteFuncionar.map((item, index) => {
            this.dadosParaOComponenteFuncionar[index].percent =
              this.porcentagemPorDoisSemNulo(item.quantidade, item.total);
          });
        },

        ordenaQuantidade(){
            if(this.$props.dados.length > 0){
                return this.$props.dados.sort((a, b) => b.quantidade - a.quantidade);
            }

            return this.$props.dados
        },
  
        ordenarAlfa(asc) {
          this.tipoOrdemAlfa = asc;
          this.adicionarCampoPorcentagem();
          Promise.all(
            this.dadosParaOComponenteFuncionar.sort((a, b) => {
              if (asc) {
                if (a.nome < b.nome) return -1;
                if (a.nome > b.nome) return 1;
              } else {
                if (a.nome > b.nome) return -1;
                if (a.nome < b.nome) return 1;
              }
              return 0;
            })
          ).then(() => {
            this.criacaoPreenchimentoCharts(this.dadosParaOComponenteFuncionar);
          });
        },
  
        ordenarPorcentAsc() {
          this.adicionarCampoPorcentagem();
          Promise.all(
            this.dadosParaOComponenteFuncionar.sort((a, b) => {
              if (parseInt(a.percent) < parseInt(b.percent)) return -1;
              if (parseInt(a.percent) > parseInt(b.percent)) return 1;
              return 0;
            })
          ).then(() => {
            this.criacaoPreenchimentoCharts(this.dadosParaOComponenteFuncionar);
          });
        },
  
        criacaoPreenchimentoCharts() {
          this.chartData = [];
          for (const element of this.dadosParaOComponenteFuncionar) {
            const chartClone = JSON.parse(JSON.stringify(this.chart));
            const porcentagem = this.porcentagemPorDoisSemNulo(
              element.quantidade,
              element.total
            );
            const codigoCor = this.condicaoCor(porcentagem);
            chartClone.options.colors[0] = codigoCor;
            chartClone.series[0] = porcentagem;
            this.chartData.push(chartClone);
          }
        },
  
        condicaoCor(porcentagem) {
          if (porcentagem == 0 || porcentagem == "") {
            return "#e9ecef";
          } else if (porcentagem > 0 && porcentagem <= 19) {
            return "#AA228C";
          } else if (porcentagem >= 20 && porcentagem <= 44) {
            return "#002060";
          } else if (porcentagem >= 45 && porcentagem <= 74) {
            return "#2772C0";
          } else if (porcentagem >= 75 && porcentagem <= 99) {
            return "#7030A0";
          } else if (porcentagem == 100) {
            return "#36BA00";
          }
        },
  
        porcentagemPorDoisSemNulo(valorX, valorY) {
          if (valorY != 0) {
            return ([valorX / valorY] * 100).toFixed(0);
          } else {
            return '0';
          }
        },
      }
    }
    </script>
  
  <style scoped>
  
  .ordenacao{
        justify-content: right;
        display: flex;
        margin: 5px;
     }
    .alfaBtn{
        padding: 5px;
        margin-right: 10px;
        margin-left: 10px;
        border-radius: 0px;
    }
    .percentBtn{
        padding: 5px;
        border-radius: 0px;
    }

    .scroll-area{
      height: 270px;
    }

    .card-employee-task{
      height: 340px;
    }

    @media (min-width: 768px) and (max-width: 1500px){
      .fonte{
        font-size: 10px;
      }
    }

    @media (min-width: 1200px) and (max-width: 1335px){
      .fonte{
        font-size: 11px;
      }
    }

    @media (min-width: 768px) and (max-width: 900px){
      .fonte{
        max-width: 100px;
      }
    }
  </style>
  
  